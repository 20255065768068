import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { ApiService, CustomValidationError } from 'ets-fe-ng-sdk';
import { map, retry, tap } from 'rxjs/operators';
import { IWebUserWithMember } from 'projects/evolutics-sales-ui/src/app/interfaces/app.interface';
import { Client3Service } from 'projects/evolutics-client-ui/src/app/Life/client-desk/service/client.service';
import { AgentService } from 'projects/evolutics-client-ui/src/app/Services/agent.service';
import { IWebUser } from '@Authentication/web-user/web-user-authentication.model';
import { StorageService } from '@Services/storage.service';
import { UserService } from '@Services/user.service';
import { ClientViewData } from '@Shared/models/client-desk.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService {
  baseURL = environment.apiBaseUrl + '/rest';
  retryCount = 1;
  noRetry = 0;
  readonly userKey = 'webUser';
  constructor(
    private apiS: ApiService,
    private agentS: AgentService,
    private userS: UserService,
    private clientS: Client3Service,
    public sS: StorageService,
  ) {
    this.getFromLocal();
  }

  getMemberCodesByChannel = (channel: string) => {
    return this.apiS
      .get(`${this.baseURL}/sales/team/member/search`, {
        channel,
        access: 'S',
      })
      .pipe(
        retry(this.retryCount),
        map((res) => res.content),
      );
  };

  getWebUsers = (channel: string) => {
    return this.apiS
      .get(`${this.baseURL}/web/access/search`, {
        channel,
        access: 'S',
      })
      .pipe(map((res) => res.content as any[]));
  };

  getMemberCodesByCode = (code: string) => {
    return this.apiS
      .get(`${this.baseURL}/sales/team/member/search`, { memberCode: code })
      .pipe(map((res) => res.content));
  };

  getTeamMemberCodes = () => {
    return this.apiS.get(`${this.baseURL}/sales/team/member/search`).pipe(map((res) => res.content as any[]));
  };

  searchMemberCodes = (query: any) => {
    return this.apiS.get(`${this.baseURL}/sales/team/member/search`, query).pipe(
      retry(this.retryCount),
      map((res) => res.content),
    );
  };

  getTeamCodes = () => {
    return this.apiS.get(`${this.baseURL}/sales/team/search`).pipe(map((res) => res.content as any[]));
  };

  getTeamCode = (teamCode: string) => {
    return this.apiS
      .get(`${this.baseURL}/sales/team/search`, { teamCode })
      .pipe(map((res) => res.content[0] as any));
  };

  createSalesMember(data: any) {
    return this.apiS.post(`${this.baseURL}/sales/team/member`, data).pipe();
  }

  validatePrimaryCode(primaryCode: string) {
    return this.apiS
      .get(`${this.baseURL}/sales/team/members/validate/${primaryCode}`)
      .pipe(map((res) => res.response as boolean));
  }

  validateMemberCode(memberCode: string) {
    return this.apiS.getText(`${this.baseURL}/web/access/validate/member/access/${memberCode}`);
  }

  asyncValidatePrimaryCode = async (control: AbstractControl) => {
    return new Promise<CustomValidationError>(async (resolve) => {
      if (!control.value) resolve(null);
      else
        try {
          const res = await this.validatePrimaryCode(control.value).toPromise();
          if (res === true) {
            return resolve({ custom: 'Primary Code Exist' });
          }
          const parent = control.parent as FormGroup;
          let data: any;
          if (parent.controls.category.value === 'A') {
            data = await this.agentS.getAgentByNo(control.value).toPromise();
            parent.patchValue({
              memberName: data.agentInformation?.name,
              channel: data.agentInformation?.channel,
            });
          }
          if (parent.controls.category.value === 'R') {
            data = await this.userS.getUserByCode(control.value).toPromise();
            parent.patchValue({
              memberName: `${data?.firstName} ${data?.lastName}`,
              channel: null,
            });
          }
          resolve(null);
        } catch (error) {
          resolve({ custom: error });
        }
    });
  };

  getWebGroups = () => {
    return this.apiS.get(`${this.baseURL}/web/access/group/search`).pipe(
      retry(this.retryCount),
      map((res) => res.content as any[]),
    );
  };

  createWebUser(data: any) {
    return this.apiS.post(`${this.baseURL}/web/access`, data).pipe();
  }

  updateWebUser(id: number, data: any) {
    return this.apiS.put(`${this.baseURL}/web/access/${id}`, data).pipe();
  }
  getTeamMembersByChannel = (channel: string) => {
    return this.apiS.get(`${this.baseURL}/sales/team/member/search`, { channel }).pipe(
      retry(this.retryCount),
      map((res) =>
        res.content.map((r: any) => {
          r['code'] = r.memberCode;
          r['description'] = r.memberName;
          return r;
        }),
      ),
    );
  };

  searchWebAccess = (query: any) => {
    return this.apiS.get(`${this.baseURL}/web/access/search`, query).pipe(map((res) => res.content as any[]));
  };

  validateClientNo = async (control: AbstractControl) => {
    // debugger
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.getClientViewData(val)
      .toPromise()
      .then((res) => {
        if (!res.clientNo) return { notFound: true };
        if (!res.type) return { notFound: true };
        (control.parent as FormGroup)?.patchValue({ fullName: res.fullName });
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };

  getClientViewData = this.clientS.getClientViewData;

  loginUser(username: string, password: string) {
    return this.apiS
      .post<IWebUser>(`${this.baseURL}/web/access/login`, {
        username,
        password,
      })
      .pipe(tap((r) => this.saveToLocal(r)));
  }

  saveToLocal(user: IWebUser) {
    environment.webUser = user;
    environment.userProfile.users.userName = user.username;
    this.sS.saveItem(this.userKey, user);
  }
  getFromLocal() {
    environment.webUser = this.sS.getItem(this.userKey);
  }

  forgetPassword(data: any) {
    return this.apiS.post(`${this.baseURL}/web/access/forgot-password/notification`, data);
  }

  /**
   * Calls the endpoint tthat validates the password refrence sent via email
   * @param reference
   * @returns
   */
  validateReference(reference: string) {
    return this.apiS.get(`${this.baseURL}/web/access/validate/reference/${reference}`);
  }

  /**
   * Calls the endpoint that updates password
   * @param reference
   * @param data
   * @returns
   */
  updatePassword(reference: string, data: any) {
    return this.apiS
      .put<IWebUser>(`${this.baseURL}/web/access/password/${reference}`, data)
      .pipe(tap((r) => this.saveToLocal(r)));
  }

  /**
   * Resend Web access credential to user
   * @param username
   * @returns
   */
  resendLoginCredentials(username: string) {
    return this.apiS.post(`${this.baseURL}/web/access/recover/credentials/${username}`);
  }

  /**
   * Calls the endpoint that returns the core user menu
   * @returns
   */
  getCoreUserMenu = () => {
    return this.apiS.get(`${this.baseURL}/users/menu/config/desc`);
  };

  /**
   * Calls the endpoint that returns the sales user menu
   * @returns
   */
  getSalesUserMenu = () => {
    return this.apiS.get(`${this.baseURL}/sales/menu/config/desc`);
  };

  /**
   * Call endpoint to get client no by member code
   * @param memberCode
   * @returns
   */
  getClientNoByMemberCode = (memberCode: string) => {
    return this.apiS.getText(`${this.baseURL}/sales/team/members/client-no/${memberCode}`);
  };

  /**
   * Call endpoint that get the team member data
   */
  getMemberViewByMemberCode = (memberCode: string) => {
    return this.apiS.get(`${this.baseURL}/sales/view/member/${memberCode}`);
  };
}
